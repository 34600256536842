import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useToggle } from '@hooks/toggle/useToggle';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { loginStatusState } from '@recoil/auth';

// components
import HamburgerMenuModal from './Modals/HamburgerMenuModal';
import MainLogo from './MainLogo';
import Icon from '@components/_atoms/Icon';

// constants
import { HOME, PUSH } from '@constants/routes/routes';
import { black, gray, white } from '@styles/Colors';

// hooks
import { useCheckScrollToTop } from '@hooks/interaction/useCheckScrollToTop';
import { useGetPushStatusFetch, usePatchPushStatusFetch } from '@hooks/fetch/usePushFetch';
import { useEffect } from 'react';
import Banner from '../Banner';
import ButtonCode from '@constants/log/buttonCode';

type Props = {
  readonly isTransparent: boolean;
};

const Layout = styled.header<{ isTransparent: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 24px;
  background: transparent;
  /* z-index: 999; */
  overflow-y: auto;

  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */

  ${({ isTransparent }) =>
    !isTransparent &&
    `
    background: ${white};
    box-shadow: 0px 2px 4px 0px #80808014;
    border-bottom: 1px solid ${gray.gray2};
  `}
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
`;

const NotiIcon = styled(Icon)``;
const HamburgerMenuIcon = styled(Icon)``;

export default function MobileHeader({ isTransparent }: Props) {
  const queryClient = useQueryClient();
  const loginStatus = useRecoilValue(loginStatusState);

  const router = useRouter();
  const isScrollToTop = useCheckScrollToTop();
  const isTransparentScrollToTop = isTransparent && isScrollToTop;

  const hamburgerMenuModal = useToggle();

  const { patchPushStatusMutate } = usePatchPushStatusFetch();
  const { getPushStatusFetchData } = useGetPushStatusFetch({ loginStatus });
  const { is_push_check } = getPushStatusFetchData ?? {};

  const onCilckNotiIcon = () => {
    patchPushStatusMutate(void 0, {
      onSuccess: () => {
        queryClient.invalidateQueries('getPushStatus');
        router.push(PUSH);
      },
    });
  };

  useEffect(() => {
    if (hamburgerMenuModal.isToggled) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [hamburgerMenuModal.isToggled]);

  return (
    <>
      {/*  */}
      <Layout isTransparent={isTransparentScrollToTop}>
        <MainLogo isTransparent={isTransparentScrollToTop} />
        <Right>
          {loginStatus ? (
            <NotiIcon
              name={is_push_check ? 'noti' : 'noti-new'}
              stroke={isTransparentScrollToTop ? white : black}
              onClick={onCilckNotiIcon}
              steelbosoId={ButtonCode.BTN_NOTI_ICON}
            />
          ) : null}
          <HamburgerMenuIcon
            name="hamburger-menu"
            stroke={isTransparentScrollToTop ? white : black}
            onClick={() => hamburgerMenuModal.toggle()}
          />
        </Right>

        <HamburgerMenuModal isOpen={hamburgerMenuModal.isToggled} onClose={() => hamburgerMenuModal.off()} />
      </Layout>
    </>
  );
}
