const pixelize = <S extends number>(size: S) => `${size}px`;

const MOBILE = 480;
const MOBILE_PX = pixelize(MOBILE);
export const MOBILE_MODE = `(max-width: ${MOBILE_PX})`;

const TABLET = 770;
const TABLET_PX = pixelize(TABLET);
export const TABLET_MODE = `(max-width: ${TABLET_PX})`;

const LAPTOP = 1160;
const LAPTOP_PX = pixelize(LAPTOP);
export const LAPTOP_MODE = `(max-width: ${LAPTOP_PX})`;
