import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { gray, white } from '@styles/Colors';
import { Time } from '@utils/time';
import { useTranslation } from 'next-i18next';

type Props = {
  readonly userName: string;
  readonly marketApporovalState: boolean;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default function MarketingApprovalMessage({ userName, marketApporovalState }: Props) {
  const { t } = useTranslation('common');
  const message = marketApporovalState
    ? t('header.profileModal.toast-marketing-approval-approve', { userName })
    : t('header.profileModal.toast-marketing-approval-disapprove', { userName });
  const curDate = Time().format('YYYY.MM.DD');

  return (
    <Layout>
      <Typography variant={TypoVariant.B4} color={white}>
        {message}
      </Typography>
      <Typography variant={TypoVariant.B4} color={gray.gray4}>
        {curDate}
      </Typography>
    </Layout>
  );
}
