import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import {
  useGetUserProfileFetch,
  usePatchUserMarketingApprovalFetch,
  usePatchUserSnsApprovalFetch,
} from '@hooks/fetch/useUserFetch';
import { gray, primary } from '@styles/Colors';
import MarketingApprovalMessage from './MarketingApprovalMessage';
import { Toast } from '@components/_atoms/Toast';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';

type Props = {
  readonly isOpen: boolean;
  readonly onClick: () => void;
};

const Layout = styled.div`
  padding: 8px;
`;

const TitleContainer = styled.div`
  width: 100%;

  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SettingIcon = styled(Icon)``;
const ArrowIcon = styled(Icon)``;
const RadioCircleIcon = styled(Icon)``;

const ContentContainer = styled.div`
  margin-top: 8px;
  padding-left: 36px;
`;

const ContentItem = styled.div`
  padding: 8px 0 8px 8px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function NotiOptionList({ isOpen, onClick }: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');

  const { getUserProfileFetchData: user } = useGetUserProfileFetch();
  const { patchUserSnsApprovalFetchMutate } = usePatchUserSnsApprovalFetch();
  const { patchUserMarketingApprovalFetchMutate } = usePatchUserMarketingApprovalFetch();

  // 클릭 -> 알림 상태변경
  const onClickEmailRadioCircle = () => {
    Toast.error(t('header.profileModal.toast-email-approval'));
  };
  const onClickSnsRadioCircle = () => {
    patchUserSnsApprovalFetchMutate(
      { sns_approval: !user?.sns_approval },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
        },
      },
    );
  };
  const onClickMarketingRadioCircle = () => {
    patchUserMarketingApprovalFetchMutate(
      { marketing_approval: !user?.marketing_approval },
      {
        onSuccess: () => {
          if (user?.name) {
            Toast.success(
              <MarketingApprovalMessage userName={user?.name} marketApporovalState={!user?.marketing_approval} />,
            );
            queryClient.invalidateQueries(['user']);
          }
        },
      },
    );
  };

  return (
    <Layout>
      <TitleContainer onClick={onClick}>
        <Left>
          <SettingIcon name="setting" stroke={primary.gray} />
          <Typography variant={TypoVariant.B4} color={primary.gray}>
            {t('header.profileModal.noti')}
          </Typography>
        </Left>
        <Right>
          <ArrowIcon
            name={isOpen ? 'arrow-up' : 'arrow-down'}
            stroke={primary.gray}
            style={{ pointerEvents: 'none' }}
          />
        </Right>
      </TitleContainer>

      {/* only select */}
      {isOpen && (
        <ContentContainer>
          <ContentItem onClick={onClickEmailRadioCircle}>
            <Typography variant={TypoVariant.B4} color={primary.gray}>
              {t('header.profileModal.email-approval')}
            </Typography>
            <RadioCircleIcon name="radio-circle-light-blue" />
          </ContentItem>

          <ContentItem onClick={onClickSnsRadioCircle}>
            <Typography variant={TypoVariant.B4} color={primary.gray}>
              {t('header.profileModal.sns-approval')}
            </Typography>
            <RadioCircleIcon
              name={user?.sns_approval ? 'radio-circle-blue' : 'radio-circle-empty'}
              style={{ cursor: 'pointer' }}
              stroke={user?.sns_approval ? 'transparent' : gray.gray6}
            />
          </ContentItem>

          <ContentItem onClick={onClickMarketingRadioCircle}>
            <Typography variant={TypoVariant.B4} color={primary.gray}>
              {t('header.profileModal.marketing-approval')}
            </Typography>
            <RadioCircleIcon
              name={user?.marketing_approval ? 'radio-circle-blue' : 'radio-circle-empty'}
              style={{ cursor: 'pointer' }}
              stroke={user?.marketing_approval ? 'transparent' : gray.gray6}
            />
          </ContentItem>
        </ContentContainer>
      )}
    </Layout>
  );
}
