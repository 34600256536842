import { BUYER, CART, HOME, PROJECT, SELLER } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { gray } from '@styles/Colors';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import LanguageOptionList from '../../LanguageOptionList';
import NavItem from './NavItem';
import NotiOptionList from './NotiOptionList';
import ButtonCode from '@constants/log/buttonCode';

const Layout = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${gray.gray3};

  display: grid;
  row-gap: 8px;
`;

const BuyerNavItem = styled(NavItem)``;
const SellerNavItem = styled(NavItem)``;
const ProjectNavItem = styled(NavItem)``;
const CartNavItem = styled(NavItem)``;

export enum OptionListType {
  Noti = 'noti',
  Language = 'language',
}

export default function AuthNavLinkList() {
  const { t } = useTranslation('common');

  const [selectNavItem, setSelectNavItem] = useState<OptionListType | ''>('');

  const handleClickNavItem = (value: OptionListType) => {
    if (value === selectNavItem) {
      setSelectNavItem('');
    } else {
      if (value === OptionListType.Language || value === OptionListType.Noti) {
        setSelectNavItem(value);
      }
    }
  };

  return (
    <Layout>
      <BuyerNavItem
        iconName={'buying'}
        text={t('header.mobile.list1')}
        linkLocation={BUYER}
        steelbosoId={ButtonCode.BTN_HEADER_BUY}
      />
      <SellerNavItem
        iconName={'store'}
        text={t('header.mobile.list2')}
        linkLocation={SELLER}
        steelbosoId={ButtonCode.BTN_HEADER_SELL}
      />
      <ProjectNavItem
        iconName={'project'}
        text={t('header.mobile.list3')}
        linkLocation={PROJECT}
        steelbosoId={ButtonCode.BTN_HEADER_PRODUCT_MANAGE}
      />
      <CartNavItem
        iconName={'cart'}
        text={t('header.mobile.list4')}
        linkLocation={CART}
        steelbosoId={ButtonCode.BTN_CART}
      />
      <NotiOptionList
        isOpen={selectNavItem === OptionListType.Noti}
        onClick={() => handleClickNavItem(OptionListType.Noti)}
      />
      <LanguageOptionList
        isOpen={selectNavItem === OptionListType.Language}
        onClick={() => handleClickNavItem(OptionListType.Language)}
      />
    </Layout>
  );
}
