import { TextButtonColor } from '@constants/atoms';
import { MOBILE_MODE } from '@constants/size';
import styled from '@emotion/styled';
import { black, gray, primary, white } from '@styles/Colors';
import { useMemo } from 'react';

type Props = Styleable & {
  readonly width?: string;
  readonly label?: string;
  readonly color?: TextButtonColor;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
};

const Button = styled.button<{ textColor: string; hoverTextColor: string }>`
  display: inline-block;
  border: none;
  background: transparent;
  padding: 13px 12px;

  font-weight: 700;
  font-size: 16px;
  color: ${({ textColor }) => textColor};
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
    color: ${({ hoverTextColor }) => hoverTextColor};
  }

  &:disabled {
    color: ${gray.gray3};
    cursor: not-allowed;
  }

  @media ${MOBILE_MODE} {
    padding: 12px 10px;
    font-size: 14px;
  }
`;

const TextButton = ({ style, width, label, color = TextButtonColor.Black, disabled = false, onClick }: Props) => {
  const [textColor, hoverTextColor] = useMemo(() => {
    switch (color) {
      case TextButtonColor.Black:
        return [primary.gray, black];
      case TextButtonColor.Gray:
        return [gray.gray6, gray.gray7];
      case TextButtonColor.White:
        return [white, white];
    }
  }, [color]);

  return (
    <Button
      style={{ width, ...style }}
      onClick={onClick}
      textColor={textColor}
      hoverTextColor={hoverTextColor}
      disabled={disabled}>
      {label}
    </Button>
  );
};

export default TextButton;
