import {
  getPushApi,
  getPushDetailApi,
  getPushStatusApi,
  patchAllPushApi,
  patchPartialPushApi,
  patchPushStatusApi,
} from '@apis/push';
import { Toast } from '@components/_atoms/Toast';
import { AxiosError } from 'axios';
import { pushPaylaod } from 'models/Supplement';
import {
  GetPushError,
  PushPartialUpdateError,
  PushPatchCheckPushAllError,
  PushStatusPartialUpdateError,
  PushStatusReadError,
} from 'models/swagger/data-contracts';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

/**
 *  푸시 목록 가져오기 Fetch
 *  @function useGetPushFetch
 */
export const useGetPushFetch = () => {
  const {
    data: getPushFetchData,
    fetchNextPage: getPushNextFetch,
    isLoading: getPushIsLoading,
    isError: getPushIsError,
  } = useInfiniteQuery(
    ['getPush'],
    async ({ pageParam = '' }) => {
      const result = await getPushApi({ cursor: pageParam });
      return result?.data.data;
    },
    {
      staleTime: 0,
      cacheTime: 0,
      getNextPageParam: (lastPage, allPages) => {
        const cursor = lastPage?.page?.cursor;
        if (cursor) {
          return cursor;
        }
      },
      onError: (err: AxiosError<GetPushError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  const isCustomLoading = (!getPushFetchData || getPushIsLoading) && !getPushIsError;

  return {
    getPushFetchData,
    isCustomLoading,
    getPushNextFetch,
  };
};

/**
 *  푸시 상세 조회 Fetch
 *  @function useGetPushDetailFetch
 *  @param {number} push_id push id
 */
export const useGetPushDetailFetch = ({ push_id }: pushPaylaod) => {
  const { data: getPushDetailFetchData } = useQuery(
    ['getPushDetail', push_id],
    async () => {
      const result = await getPushDetailApi({ push_id });
      return result?.data.data;
    },
    {
      onError: (err: AxiosError<GetPushError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    getPushDetailFetchData,
  };
};

/**
 *  특정 푸시 읽음 처리 Fetch
 *  @function usePatchPartialPushFetch
 */
export const usePatchPartialPushFetch = () => {
  const { mutate: patchPartialPushMutate } = useMutation(
    ['patchPartialPush'],
    ({ push_id }: pushPaylaod) =>
      patchPartialPushApi({
        push_id,
      }),
    {
      onError: (err: AxiosError<PushPartialUpdateError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchPartialPushMutate,
  };
};

/**
 *  모든 푸시 읽음 처리 Fetch
 *  @function usePatchAllPushFetch
 */
export const usePatchAllPushFetch = () => {
  const { mutate: patchAllPushMutate } = useMutation(['patchAllPush'], () => patchAllPushApi(), {
    onError: (err: AxiosError<PushPatchCheckPushAllError>) => {
      if (err.response?.data?.message) {
        Toast.error(err.response.data.message.text);
      }
    },
  });

  return {
    patchAllPushMutate,
  };
};

/**
 *  최근 푸시 읽음 상태 확인 Fetch
 *  @function useGetPushStatusFetch
 */
export const useGetPushStatusFetch = ({ loginStatus }: { loginStatus: boolean }) => {
  const { data: getPushStatusFetchData } = useQuery(
    ['getPushStatus'],
    async () => {
      const result = await getPushStatusApi();
      return result?.data.data;
    },
    {
      // 로그인시에만
      enabled: loginStatus,
      onError: (err: AxiosError<PushStatusReadError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    getPushStatusFetchData,
  };
};

/**
 *  최근 푸시 읽음 상태 업데이트 Fetch
 *  @function usePatchPushStatusFetch
 */
export const usePatchPushStatusFetch = () => {
  const { mutate: patchPushStatusMutate } = useMutation(['patchPushStatus'], () => patchPushStatusApi(), {
    onError: (err: AxiosError<PushStatusPartialUpdateError>) => {
      if (err.response?.data?.message) {
        Toast.error(err.response.data.message.text);
      }
    },
  });

  return {
    patchPushStatusMutate,
  };
};
