import styled from '@emotion/styled';
import UnAuthNavLinkList from './UnAuthNavLinkList';
import Title from './Title';

const Layout = styled.div``;

export default function UnAuthMenuContent() {
  return (
    <Layout>
      <Title />
      <UnAuthNavLinkList />
    </Layout>
  );
}
