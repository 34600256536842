import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { RefObject } from 'react';
import { useQueryClient } from 'react-query';

// components
import RefIcon from '@components/_molecules/RefIcon';
import NavItem from './NavItem';

// constants
import { BUYER, CART, PROJECT, SELLER } from '@constants/routes/routes';
import { black, white } from '@styles/Colors';

// hooks
import { useGetPushStatusFetch, usePatchPushStatusFetch } from '@hooks/fetch/usePushFetch';

// type
import { AuthHeaderModalType } from '..';
import ButtonCode from '@constants/log/buttonCode';

type Props = {
  readonly isTransparent: boolean;
  readonly onClickIcon: (modalType: AuthHeaderModalType) => void;
  readonly pushIconRef: RefObject<HTMLDivElement>;
  readonly profileIconRef: RefObject<HTMLDivElement>;
};

const Layout = styled.div`
  display: flex;
  height: 100%;
`;
const LinkList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
`;

const UserIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  column-gap: 24px;
`;

const NotiIcon = styled(RefIcon)``;
const UserIcon = styled(RefIcon)``;

export default function AuthNavList({ isTransparent, onClickIcon, pushIconRef, profileIconRef }: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');

  const { getPushStatusFetchData } = useGetPushStatusFetch({ loginStatus: true });
  const { is_push_check } = getPushStatusFetchData ?? {};
  const { patchPushStatusMutate } = usePatchPushStatusFetch();

  const handlePushIconClick = () => {
    patchPushStatusMutate(void 0, {
      onSuccess: () => {
        queryClient.invalidateQueries('getPushStatus');
        onClickIcon(AuthHeaderModalType.Push);
      },
    });
  };

  return (
    <Layout>
      <LinkList>
        <NavItem
          link={BUYER}
          title={t('header.service1')}
          isTransparent={isTransparent}
          steelbosoId={ButtonCode.BTN_HEADER_BUY}
        />
        <NavItem
          link={SELLER}
          title={t('header.service2')}
          isTransparent={isTransparent}
          steelbosoId={ButtonCode.BTN_HEADER_SELL}
        />
        <NavItem
          link={CART}
          title={t('header.profileModal.cart')}
          isTransparent={isTransparent}
          steelbosoId={ButtonCode.BTN_CART}
        />
        <NavItem
          link={PROJECT}
          title={t('header.service3')}
          isTransparent={isTransparent}
          steelbosoId={ButtonCode.BTN_HEADER_PRODUCT_MANAGE}
        />
      </LinkList>
      <UserIconContainer>
        <NotiIcon
          steelbosoId={ButtonCode.BTN_NOTI_ICON}
          ref={pushIconRef}
          onClick={handlePushIconClick}
          name={is_push_check ? 'noti' : 'noti-new'}
          stroke={isTransparent ? white : black}
        />
        <UserIcon
          steelbosoId={ButtonCode.BTN_USER_ICON}
          ref={profileIconRef}
          onClick={() => onClickIcon(AuthHeaderModalType.Profile)}
          name="user"
          stroke={isTransparent ? white : black}
        />
      </UserIconContainer>
    </Layout>
  );
}
