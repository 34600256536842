import {
  BUYER,
  CHANNEL_TALK,
  COMPANY_HIRE_NOTION,
  COMPANY_INTRO,
  ESTIMATE_FILE_KO,
  ESTIMATE_FILE_EN,
  FACEBOOK_EN,
  FACEBOOK_KO,
  LINKED_IN,
  MEDIUM,
  BLOG,
  SELLER,
} from '@constants/routes/routes';
import styled from '@emotion/styled';
import { useState } from 'react';
import LanguageOptionList from '../../LanguageOptionList';
import AccodianNavItem from './AccodianNavItem';
import { useTranslation } from 'next-i18next';
import ButtonCode from '@constants/log/buttonCode';

const Layout = styled.div`
  padding: 24px 0;

  display: grid;
  row-gap: 8px;
`;

const ChatNavItem = styled(AccodianNavItem)``;
const CommunicationNavItem = styled(AccodianNavItem)``;
const SupportNavItem = styled(AccodianNavItem)``;
const ServiceNavItem = styled(AccodianNavItem)``;

export enum UnAuthSelectNavItemType {
  Intro = 'intro',
  Communication = 'communication',
  Support = 'support',
  Service = 'service',
  Language = 'language',
}

export default function UnAuthNavLinkList() {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';
  const [selectNavItem, setSelectNavItem] = useState<UnAuthSelectNavItemType | ''>('');

  const handleClickNavItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.target as HTMLButtonElement;

    if (value === selectNavItem) {
      setSelectNavItem('');
    } else {
      if (
        value === UnAuthSelectNavItemType.Intro ||
        value === UnAuthSelectNavItemType.Communication ||
        value === UnAuthSelectNavItemType.Support ||
        value === UnAuthSelectNavItemType.Service ||
        value === UnAuthSelectNavItemType.Language
      ) {
        setSelectNavItem(value);
      }
    }
  };

  return (
    <Layout>
      <ChatNavItem
        iconName="logo-black"
        title={t('header.marketing.title1')}
        content={[
          {
            link: COMPANY_INTRO,
            content: t('header.marketing.content1-1'),
            steelbosoId: ButtonCode.BTN_HEADER_COMPANY_ABOUT,
          },
          {
            link: COMPANY_HIRE_NOTION,
            content: t('header.marketing.content1-2'),
            steelbosoId: ButtonCode.BTN_HEADER_COMPANY_CAREERS,
          },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Intro}
        value={UnAuthSelectNavItemType.Intro}
        onClick={handleClickNavItem}
      />
      <CommunicationNavItem
        iconName="chat"
        title={t('header.marketing.title2')}
        content={[
          { link: BLOG, content: t('header.marketing.content2-1'), steelbosoId: ButtonCode.BTN_HEADER_BLOG },
          {
            link: ko ? FACEBOOK_KO : FACEBOOK_EN,
            content: t('header.marketing.content2-2'),
            steelbosoId: ButtonCode.BTN_HEADER_FACEBOOK,
          },
          { link: LINKED_IN, content: t('header.marketing.content2-3'), steelbosoId: ButtonCode.BTN_HEADER_LINKEDIN },
          { link: MEDIUM, content: t('header.marketing.content2-4'), steelbosoId: ButtonCode.BTN_HEADER_MEDIUM },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Communication}
        value={UnAuthSelectNavItemType.Communication}
        onClick={handleClickNavItem}
      />
      <SupportNavItem
        iconName="alert-more"
        title={t('header.marketing.title3')}
        content={[
          {
            link: CHANNEL_TALK,
            content: t('header.marketing.content3-1'),
            steelbosoId: ButtonCode.BTN_HEADER_SUPPORT_CHAT,
          },
          {
            link: ko ? ESTIMATE_FILE_KO : ESTIMATE_FILE_EN,
            content: t('header.marketing.content3-2'),
            steelbosoId: ButtonCode.BTN_HEADER_SUPPORT_STANDARD_QUOTE,
          },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Support}
        value={UnAuthSelectNavItemType.Support}
        onClick={handleClickNavItem}
      />
      <ServiceNavItem
        iconName="project"
        title={t('header.marketing.title4')}
        content={[
          { link: BUYER, content: t('header.marketing.content4-1'), steelbosoId: ButtonCode.BTN_HEADER_BUY },
          { link: SELLER, content: t('header.marketing.content4-2'), steelbosoId: ButtonCode.BTN_HEADER_SELL },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Service}
        value={UnAuthSelectNavItemType.Service}
        onClick={handleClickNavItem}
      />
      <LanguageOptionList isOpen={selectNavItem === UnAuthSelectNavItemType.Language} onClick={handleClickNavItem} />
    </Layout>
  );
}
