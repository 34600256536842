import styled from '@emotion/styled';
import Image from 'next/image';

const Layout = styled.div`
  width: 144px;
  height: 24px;
  position: relative;
`;

export default function MainLogo() {
  return (
    <Layout>
      <Image src="/assets/common/logo-d.svg" layout="fill" alt="logo" />
    </Layout>
  );
}
