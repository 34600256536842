import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import ButtonCode from '@constants/log/buttonCode';
import { LOGIN, SIGNUP } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { gray, primary } from '@styles/Colors';
import { Radius } from '@styles/Radius';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const UserName = styled.div``;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 32px;
  border-bottom: 1px solid ${gray.gray3};
`;

const LinkWrapper = styled.div`
  margin-top: 14px;

  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const ColumnDivider = styled.div`
  height: 16px;
  border: 1px solid ${gray.gray6};
  border-radius: ${Radius.MEDIUM};
`;

export default function Title() {
  const { t } = useTranslation('common');

  return (
    <Layout>
      <UserName>
        <Typography variant={TypoVariant.SH2} color={primary.gray}>
          {t('header.mobile.title1')}
        </Typography>
      </UserName>
      <LinkWrapper>
        <Link href={LOGIN}>
          <a data-steelboso-id={ButtonCode.BTN_LOGIN}>
            <Typography variant={TypoVariant.SH4} color={gray.gray6}>
              {t('header.mobile.login')}
            </Typography>
          </a>
        </Link>
        <ColumnDivider />
        <Link href={SIGNUP}>
          <a data-steelboso-id={ButtonCode.BTN_JOIN}>
            <Typography variant={TypoVariant.SH4} color={gray.gray6}>
              {t('header.mobile.signUp')}
            </Typography>
          </a>
        </Link>
      </LinkWrapper>
    </Layout>
  );
}
