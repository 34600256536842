import { ReactNode, RefObject, useMemo } from 'react';
import styled from '@emotion/styled';
import { TypoVariant } from '@constants/atoms';
import { useGetTypoSize } from '@hooks/atoms/useGetTypoSize';
import { primary } from '@styles/Colors';

type Props = Styleable & {
  readonly variant: TypoVariant;
  readonly color?: string;
  readonly children: ReactNode;
  readonly onClick?: () => void;
  readonly typoRef?: RefObject<HTMLSpanElement>;
};

type CssProps = {
  color: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  textAlign?: string;
};

const Text = styled.span<CssProps>`
  font-family: 'Pretendard Variable';
  word-break: keep-all;
  white-space: pre-line;
  letter-spacing: -2%;

  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
`;

const Typography = ({ variant, color = primary.gray, children, style, onClick, typoRef }: Props) => {
  const [fontWeight, fontSize, lineHeight] = useGetTypoSize(variant);

  return (
    <Text
      style={{ ...style }}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      onClick={onClick}
      ref={typoRef}>
      {children}
    </Text>
  );
};

export default Typography;
