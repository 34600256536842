import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import ButtonCode from '@constants/log/buttonCode';
import { MYPAGE } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';
import { gray, primary } from '@styles/Colors';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const UserName = styled.div``;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 32px;
  border-bottom: 1px solid ${gray.gray3};
`;

const ProfileLinkButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`;

const ArrowRightIcon = styled(Icon)``;

export default function Title() {
  const { t } = useTranslation('common');
  const { getUserProfileFetchData: user } = useGetUserProfileFetch();

  return (
    <Layout>
      <UserName>
        <Typography variant={TypoVariant.SH2} color={primary.gray}>
          {t('header.mobile.title2-1')}
          {user?.name}
          {t('header.mobile.title2-2')}
        </Typography>
      </UserName>
      <Link href={MYPAGE}>
        <a data-steelboso-id={ButtonCode.BTN_MYPAGE}>
          <ProfileLinkButton>
            <Typography variant={TypoVariant.B4} color={gray.gray7}>
              {t('header.mobile.profile')}
            </Typography>
            <ArrowRightIcon name="arrow-right" stroke={gray.gray7} />
          </ProfileLinkButton>
        </a>
      </Link>
    </Layout>
  );
}
