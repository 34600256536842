import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { SIGNUP } from '@constants/routes/routes';
import { LAPTOP_MODE, TABLET_MODE } from '@constants/size';
import styled from '@emotion/styled';
import { useIsLaptop, useIsMobile, useIsTablet } from '@hooks/responsive/useIsMobile';
import { gray, red, white } from '@styles/Colors';
import { getCookie, setCookie } from '@utils/cookie';
import { Time } from '@utils/time';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type Props = {
  loginStatus: boolean;
};

const Layout = styled.div`
  height: 72px;
  background-color: #111524;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;

  @media ${LAPTOP_MODE} {
    column-gap: 40px;
  }
  @media ${TABLET_MODE} {
    column-gap: 16px;
  }
`;

const Text = styled.span`
  font-weight: 400;
`;

const CloseBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  position: absolute;
  right: 40px;
  top: 26px;

  @media ${TABLET_MODE} {
    right: 21.5px;
  }
`;

// only PC
export default function Banner({ loginStatus }: Props) {
  const router = useRouter();
  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';
  const HIDE_BANNER_FOR_TODAY = 'hideBannerForToday';
  const hideBannerForToday = getCookie(HIDE_BANNER_FOR_TODAY);

  const isLaptop = useIsLaptop();
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);

  // 배너 set
  useEffect(() => {
    if (router.pathname !== '/') return; // Home에서만 노출
    if (!ko) return; // ko에서만 노출

    // 일자 추가 2024-09-19 이전 일자만 나오도록
    const now = dayjs();
    const isWithinDateRange = now.isBefore('2024-09-19'); // now.isAfter('2024-09-11')

    if (!hideBannerForToday && isWithinDateRange) {
      setOpen(true);
      return;
    }
  }, [loginStatus, router, ko]);

  // 배너 close
  const closeBanner = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const today = Time();
    const tomorrow = Time().add(1, 'day').startOf('day');
    const maxAge = tomorrow.diff(today, 'second');

    setCookie(HIDE_BANNER_FOR_TODAY, today, { maxAge });
    setOpen(false);
  };

  return (
    <>
      {!isMobile && open && (
        <Layout onClick={() => router.push(SIGNUP)}>
          <Content>
            <Image src={`/assets/images/calender.png`} width={61} height={40} alt="banner-calender" />
            <Typography variant={TypoVariant.SH3} color={white}>
              추석연휴 기간에는 견적대응이 잠시 중단됩니다.
            </Typography>
            <Typography variant={TypoVariant.B2} color={gray.gray6}>
              |
            </Typography>
            <Typography variant={TypoVariant.SH3} color={red.red3}>
              2024.09.14(토) ~ 2024.09.18(수)
            </Typography>
          </Content>
          <CloseBox onClick={closeBanner}>
            {!isLaptop && (
              <Typography variant={TypoVariant.B4} color={white}>
                오늘 하루 보지 않기
              </Typography>
            )}
            <Icon onClick={() => {}} name="close" stroke={white} style={{ width: 20, height: 20 }} />
          </CloseBox>
        </Layout>
      )}
    </>
  );
}
