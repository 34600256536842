import styled from '@emotion/styled';
import AuthMenuContent from './AuthMenuContent';
import UnAuthMenuContent from './UnAuthMenuContent';
import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';

const Layout = styled.div``;

export default function MenuContent() {
  const { getUserProfileFetchData: user } = useGetUserProfileFetch();
  const isAuth = !!user;

  return <Layout>{isAuth ? <AuthMenuContent /> : <UnAuthMenuContent />}</Layout>;
}
