import Icon from '@components/_atoms/Icon';
import styled from '@emotion/styled';
import { black, white } from '@styles/Colors';
import DefaultLogo from '@public/assets/common/logo-d.webp';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { HOME } from '@constants/routes/routes';
import MenuContent from './MenuContent';
import ButtonCode from '@constants/log/buttonCode';

type Props = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

const Layout = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${white};

  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.isOpen === false &&
    `
      display:none;
    `}
`;

const CloseIcon = styled(Icon)``;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  width: 100%;
  height: 56px;
`;
const MainLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MainLogoImage = styled(Image)``;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 32px;
`;

export default function HamburgerMenuModal({ isOpen, onClose }: Props) {
  const roter = useRouter();

  const onClickMainLogo = () => {
    onClose();
    roter.push(HOME);
  };

  return (
    <Layout isOpen={isOpen}>
      <Head>
        <MainLogoContainer onClick={onClickMainLogo} data-steelboso-id={ButtonCode.BTN_HEADER_HOME}>
          <MainLogoImage src={DefaultLogo} width={120} height={20} alt="main-logo" />
        </MainLogoContainer>
        <CloseIcon name="close" stroke={black} onClick={onClose} />
      </Head>
      <Body>
        <MenuContent />
      </Body>
    </Layout>
  );
}
