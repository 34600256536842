import styled from '@emotion/styled';

// components
import MarketingItem from './MarketingItem';
import { TABLET_MODE } from '@constants/size';
import { DefaultModalType } from '../../Modals/DefaultModal';
import { useTranslation } from 'next-i18next';

type Props = {
  readonly isTransparent: boolean;
};

const Layout = styled.div`
  margin-left: 32px;

  display: flex;
  align-items: center;
  column-gap: 16px;

  @media ${TABLET_MODE} {
    display: none;
  }
`;

export default function NavMarketingList({ isTransparent }: Props) {
  const { t } = useTranslation('common');

  return (
    <Layout>
      <MarketingItem title={t('header.marketing.title1')} type={DefaultModalType.Intro} isTransparent={isTransparent} />
      <MarketingItem
        title={t('header.marketing.title2')}
        type={DefaultModalType.Communication}
        isTransparent={isTransparent}
      />
      <MarketingItem
        title={t('header.marketing.title3')}
        type={DefaultModalType.Support}
        isTransparent={isTransparent}
      />
      <MarketingItem
        title={t('header.marketing.title4')}
        type={DefaultModalType.Service}
        isTransparent={isTransparent}
      />
    </Layout>
  );
}
