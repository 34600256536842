import styled from '@emotion/styled';
import Link from 'next/link';

// components
import Typography from '@components/_atoms/Typography';
import MainLogo from './MainLogo';

// constants
import { gray, primary } from '@styles/Colors';
import { TypoVariant } from '@constants/atoms';
import { LAPTOP_MODE, MOBILE_MODE, TABLET_MODE } from '@constants/size';
import {
  CHANNEL_TALK,
  COMPANY_HIRE_NOTION,
  COMPANY_INTRO,
  COMPANY_MARKETING,
  COMPANY_POLICY,
  COMPANY_USE,
  ESTIMATE_FILE_KO,
  ESTIMATE_FILE_EN,
  FACEBOOK_EN,
  FACEBOOK_KO,
  LINKED_IN,
  MEDIUM,
  BLOG,
  SELLER,
  BUYER,
} from '@constants/routes/routes';
import { useTranslation } from 'next-i18next';
import ButtonCode from '@constants/log/buttonCode';
import { useIsLaptop, useIsMobile, useIsTablet } from '@hooks/responsive/useIsMobile';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-top: 1px solid ${gray.gray4};

  @media ${MOBILE_MODE} {
    padding: 20px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  gap: 40px;
`;

const FooterContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${LAPTOP_MODE}, ${TABLET_MODE}, ${MOBILE_MODE} {
    flex-direction: column;
    gap: 40px;
  }

  @media ${TABLET_MODE} {
    gap: 40px;
  }

  @media ${MOBILE_MODE} {
    gap: 24px;
  }
`;

const IntrolTextContainer = styled.div`
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  flex: 1 0 0;
  align-self: stretch;

  @media (max-width: 1440px) {
    min-width: 520px;
  }

  @media (max-width: 600px) {
    min-width: auto;
  }
`;

const IntroTextWrapper = styled.div``;

const CompanyIntroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

const CompanyIntroTextListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Divider = styled.div`
  width: 2px;
  height: 15px;
  background-color: ${gray.gray4};
  border-radius: 1px;
  margin: 0 3px;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
`;

const LinkListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  flex-wrap: wrap;
  height: 100%;

  @media ${TABLET_MODE}, ${MOBILE_MODE} {
    height: auto;
  }
`;

const LinkListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  min-width: 105.6px;
  max-width: 105.6px;
`;

const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const CompanyInfoTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const Copylight = (): React.ReactElement => (
  <Typography variant={TypoVariant.B4} color={gray.gray5}>
    Copyright ⓒ Marketofmaterial Inc. All Rights Reserved.
  </Typography>
);

const LinkList = (): React.ReactElement => {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';
  const STEELBOSO_EMAIL = 'mailto:support@steelboso.com';

  return (
    <LinkListContainer>
      <LinkListWrapper>
        <Typography variant={TypoVariant.SH4} color={primary.gray}>
          {t('footer.menu3')}
        </Typography>
        <Link href={COMPANY_INTRO}>
          <a>
            <Typography
              variant={TypoVariant.SH4}
              color={gray.gray6}
              data-steelboso-id={ButtonCode.BTN_FOOTER_COMPANY_ABOUT}>
              {t('footer.content3-1')}
            </Typography>
          </a>
        </Link>
        <a
          href={COMPANY_HIRE_NOTION}
          target="_blank"
          rel="noreferrer"
          data-steelboso-id={ButtonCode.BTN_FOOTER_COMPANY_CAREERS}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content3-2')}
          </Typography>
        </a>
      </LinkListWrapper>

      <LinkListWrapper>
        <Typography variant={TypoVariant.SH4} color={primary.gray}>
          {t('footer.menu6')}
        </Typography>
        <Link href={BUYER}>
          <a data-steelboso-id={ButtonCode.BTN_FOOTER_BUY}>
            <Typography variant={TypoVariant.SH4} color={gray.gray6}>
              {t('footer.content6-1')}
            </Typography>
          </a>
        </Link>
        <Link href={SELLER}>
          <a data-steelboso-id={ButtonCode.BTN_FOOTER_SELL}>
            <Typography variant={TypoVariant.SH4} color={gray.gray6}>
              {t('footer.content6-2')}
            </Typography>
          </a>
        </Link>
      </LinkListWrapper>

      <LinkListWrapper>
        <Typography variant={TypoVariant.SH4} color={primary.gray}>
          {t('footer.menu2')}
        </Typography>
        <a href={CHANNEL_TALK} target="_blank" rel="noreferrer" data-steelboso-id={ButtonCode.BTN_FOOTER_SUPPORT_CHAT}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content2-1')}
          </Typography>
        </a>
        <a href={STEELBOSO_EMAIL} data-steelboso-id={ButtonCode.BTN_FOOTER_EMAIL}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content2-2')}
          </Typography>
        </a>
        <a
          href={ko ? ESTIMATE_FILE_KO : ESTIMATE_FILE_EN}
          target="_blank"
          rel="noreferrer"
          data-steelboso-id={ButtonCode.BTN_FOOTER_SUPPORT_STANDARD_QUOTE}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content2-3')}
          </Typography>
        </a>
      </LinkListWrapper>

      <LinkListWrapper>
        <Typography variant={TypoVariant.SH4} color={primary.gray}>
          {t('footer.menu4')}
        </Typography>
        <a href={BLOG} target="_blank" rel="noreferrer" data-steelboso-id={ButtonCode.BTN_FOOTER_COMPANY_CAREERS}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content4-1')}
          </Typography>
        </a>
      </LinkListWrapper>

      <LinkListWrapper>
        <Typography variant={TypoVariant.SH4} color={primary.gray}>
          {t('footer.menu5')}
        </Typography>
        <a
          href={ko ? FACEBOOK_KO : FACEBOOK_EN}
          target="_blank"
          rel="noreferrer"
          data-steelboso-id={ButtonCode.BTN_FOOTER_FACEBOOK}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content4-2')}
          </Typography>
        </a>
        <a href={LINKED_IN} target="_blank" rel="noreferrer" data-steelboso-id={ButtonCode.BTN_FOOTER_LINKEDIN}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content4-3')}
          </Typography>
        </a>
        <a href={MEDIUM} target="_blank" rel="noreferrer" data-steelboso-id={ButtonCode.BTN_FOOTER_MEDIUM}>
          <Typography variant={TypoVariant.SH4} color={gray.gray6}>
            {t('footer.content4-4')}
          </Typography>
        </a>
      </LinkListWrapper>
    </LinkListContainer>
  );
};

export default function Footer() {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';

  const isLaptop = useIsLaptop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const introTextSize = isMobile ? TypoVariant.H6 : TypoVariant.H5;

  return (
    <FooterContainer>
      <FooterWrapper>
        <MainLogo />
        <FooterContentWrapper>
          <IntrolTextContainer>
            <IntroTextWrapper>
              {ko ? (
                <>
                  <Typography variant={introTextSize} color={primary.gray}>
                    성공적인 철강재 거래,
                  </Typography>
                  <br />
                  <Typography variant={introTextSize} color={primary.blue}>
                    스틸보소
                  </Typography>
                  <Typography variant={introTextSize} color={primary.gray}>
                    와 함께하세요.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant={introTextSize} color={primary.gray}>
                    Ensure successful steel
                  </Typography>
                  <br />
                  <Typography variant={introTextSize} color={primary.gray}>
                    transactions with&nbsp;
                  </Typography>
                  <Typography variant={introTextSize} color={primary.blue}>
                    Steelboso.
                  </Typography>
                </>
              )}
            </IntroTextWrapper>

            {isLaptop && !isMobile && <LinkList />}

            <CompanyIntroTextContainer>
              <CompanyIntroTextListWrapper>
                <CompanyInfoTextWrapper>
                  <Typography variant={TypoVariant.SH4} color={gray.gray7}>
                    {ko ? '대표번호' : 'Tel'}
                  </Typography>
                  <Divider />
                  <Typography variant={TypoVariant.B4} color={gray.gray5}>
                    1670-1621
                  </Typography>
                </CompanyInfoTextWrapper>

                <CompanyInfoTextWrapper>
                  <Typography variant={TypoVariant.SH4} color={gray.gray7}>
                    {ko ? '이메일' : 'Email'}
                  </Typography>
                  <Divider />
                  <Typography variant={TypoVariant.B4} color={gray.gray5}>
                    support@steelboso.com
                  </Typography>
                </CompanyInfoTextWrapper>

                <CompanyInfoTextWrapper>
                  <Typography variant={TypoVariant.SH4} color={gray.gray7}>
                    {ko ? '본사' : 'HQ Address'}
                  </Typography>
                  <Divider />
                  <Typography variant={TypoVariant.B4} color={gray.gray5}>
                    {ko
                      ? '울산광역시 울주군 언양읍 유니스트길 50, UNIST 101동 251호'
                      : '101-251, 50, UNIST-gil, Eonyang-eup, Ulju-gun, Ulsan, Republic of Korea'}
                  </Typography>
                </CompanyInfoTextWrapper>

                <CompanyInfoTextWrapper>
                  <Typography variant={TypoVariant.SH4} color={gray.gray7}>
                    {ko ? '연구소' : 'Lab Address'}
                  </Typography>
                  <Divider />
                  <Typography variant={TypoVariant.B4} color={gray.gray5}>
                    {ko
                      ? '서울특별시 서초구바우뫼로 41길 8 장훈빌딩 302호'
                      : '302, Janghun Building, 8, Baumoe-ro 41-gil, Seocho-gu, Seoul, Republic of Korea'}
                  </Typography>
                </CompanyInfoTextWrapper>
              </CompanyIntroTextListWrapper>
            </CompanyIntroTextContainer>

            {!isTablet && !isLaptop && <Copylight />}
          </IntrolTextContainer>

          <LinkContainer>
            {!isLaptop && <LinkList />}
            {!isMobile && (
              <>
                <CompanyInfoContainer>
                  <CompanyInfoTextWrapper>
                    <Link href={COMPANY_USE}>
                      <a target="_blank" data-steelboso-id={ButtonCode.BTN_SERVICE_TERMS}>
                        <Typography variant={TypoVariant.SH4} color={gray.gray6}>
                          {t('footer.content5-1')}
                        </Typography>
                      </a>
                    </Link>
                    <Divider />
                    <Link href={COMPANY_POLICY}>
                      <a data-steelboso-id={ButtonCode.BTN_POLICY}>
                        <Typography variant={TypoVariant.SH4} color={gray.gray6}>
                          {t('footer.content5-2')}
                        </Typography>
                      </a>
                    </Link>
                    <Divider />
                    <Link href={COMPANY_MARKETING}>
                      <a data-steelboso-id={ButtonCode.BTN_MARKETING}>
                        <Typography variant={TypoVariant.SH4} color={gray.gray6}>
                          {t('footer.content5-3')}
                        </Typography>
                      </a>
                    </Link>
                  </CompanyInfoTextWrapper>

                  <CompanyInfoTextWrapper>
                    <Typography variant={TypoVariant.B4} color={gray.gray6}>
                      {ko ? '주식회사 마켓오브메테리얼' : 'Market Of Material Co.,Ltd.'}
                    </Typography>
                    <Divider />
                    <Typography variant={TypoVariant.B4} color={gray.gray6}>
                      {ko ? '대표 조윤기' : 'CEO: Jo Yun Ki'}
                    </Typography>
                    <Divider />
                    <Typography variant={TypoVariant.B4} color={gray.gray6}>
                      {ko ? '사업자등록번호' : 'Business Registration Number'}: 435-86-01645
                    </Typography>
                    <Divider />
                    <Typography variant={TypoVariant.B4} color={gray.gray6}>
                      {ko ? '통신판매업 신고번호' : 'Number for mail-order sale'}:{' '}
                      {ko ? '2021-울산울주-0551호' : '2021-UlsanUlju-0551'}
                    </Typography>
                  </CompanyInfoTextWrapper>
                </CompanyInfoContainer>
              </>
            )}
          </LinkContainer>
        </FooterContentWrapper>

        {(isTablet || isLaptop) && <Copylight />}
      </FooterWrapper>
    </FooterContainer>
  );
}
