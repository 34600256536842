import Link from 'next/link';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';

// components
import Typography from '@components/_atoms/Typography';

// constants
import {
  BUYER,
  CHANNEL_TALK,
  COMPANY_HIRE_NOTION,
  COMPANY_INTRO,
  ESTIMATE_FILE_KO,
  ESTIMATE_FILE_EN,
  FACEBOOK_EN,
  FACEBOOK_KO,
  FAQ,
  LINKED_IN,
  MEDIUM,
  BLOG,
  SELLER,
  SIGNUP,
} from '@constants/routes/routes';
import { Radius } from '@styles/Radius';
import { Shadow } from '@styles/Shadow';
import { gray, primary, white } from '@styles/Colors';
import { TypoVariant } from '@constants/atoms';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import ButtonCode from '@constants/log/buttonCode';

type Props = {
  readonly isOpen: boolean;
  readonly type: DefaultModalType | undefined;
};

export enum DefaultModalType {
  Intro = 'intro',
  Communication = 'communication',
  Support = 'support',
  Service = 'service',
}

const DefaultModalContainer = styled.div<{ isOpen: boolean }>`
  padding: 8px;
  background: ${white};
  border-radius: ${Radius.MEDIUM};
  box-shadow: ${Shadow.MEDIUM};
  position: absolute;
  top: 64px;

  display: none;

  ${({ isOpen }) =>
    isOpen &&
    `
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: max-content;
  `}
`;

const ModalItem = styled.div`
  width: 128px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${gray.gray1};
  }
`;

type ItemType = {
  content: string;
  link: string;
  steelbosoId: string;
};

const DefaultModal = ({ isOpen = false, type }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const router = useRouter();
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';
  const linkList = useMemo(() => {
    switch (type) {
      case DefaultModalType.Intro:
        return [
          {
            link: COMPANY_INTRO,
            content: t('header.marketing.content1-1'),
            steelbosoId: ButtonCode.BTN_HEADER_COMPANY_ABOUT,
          },
          {
            link: COMPANY_HIRE_NOTION,
            content: t('header.marketing.content1-2'),
            steelbosoId: ButtonCode.BTN_HEADER_COMPANY_CAREERS,
          },
        ];
      case DefaultModalType.Communication:
        return [
          { link: BLOG, content: t('header.marketing.content2-1'), steelbosoId: ButtonCode.BTN_HEADER_BLOG },
          {
            link: ko ? FACEBOOK_KO : FACEBOOK_EN,
            content: t('header.marketing.content2-2'),
            steelbosoId: ButtonCode.BTN_HEADER_FACEBOOK,
          },
          { link: LINKED_IN, content: t('header.marketing.content2-3'), steelbosoId: ButtonCode.BTN_HEADER_LINKEDIN },
          { link: MEDIUM, content: t('header.marketing.content2-4'), steelbosoId: ButtonCode.BTN_HEADER_MEDIUM },
        ];
      case DefaultModalType.Support:
        return [
          {
            link: CHANNEL_TALK,
            content: t('header.marketing.content3-1'),
            steelbosoId: ButtonCode.BTN_HEADER_SUPPORT_CHAT,
          },
          {
            link: ko ? ESTIMATE_FILE_KO : ESTIMATE_FILE_EN,
            content: t('header.marketing.content3-2'),
            steelbosoId: ButtonCode.BTN_HEADER_SUPPORT_STANDARD_QUOTE,
          },
          { link: FAQ, content: t('header.marketing.content3-3'), steelbosoId: ButtonCode.BTN_HEADER_SUPPORT_FAQ },
        ];
      case DefaultModalType.Service:
        return [
          { link: BUYER, content: t('header.marketing.content4-1'), steelbosoId: ButtonCode.BTN_HEADER_BUY },
          { link: SELLER, content: t('header.marketing.content4-2'), steelbosoId: ButtonCode.BTN_HEADER_SELL },
        ];
    }
  }, [type, t]);

  return (
    <DefaultModalContainer isOpen={isOpen} ref={ref}>
      {linkList?.map((item: ItemType) => (
        <ModalItem key={item.content} onClick={() => router.push(item.link)} data-steelboso-id={item.steelbosoId}>
          <Link href={item.link}>
            <a>
              <Typography variant={TypoVariant.B4} color={primary.gray}>
                {item.content}
              </Typography>
            </a>
          </Link>
        </ModalItem>
      ))}
    </DefaultModalContainer>
  );
};

export default forwardRef(DefaultModal);
