import styled from '@emotion/styled';

// colors
import { primary, blue, gray, red, white, black } from '@styles/Colors';
import { DividerAlign } from '@constants/atoms';

type Props = {
  color?: string;
  width?: string;
  align?: DividerAlign;
};

const DividerItem = styled.div<Props>`
  height: 1px;
  background-color: ${({ color }) => color || gray.gray3};

  ${({ align }) =>
    align === DividerAlign.CENTER &&
    `
     margin:0 auto;
  `}
`;

const DEFAULT_WIDTH = '100%';

export default function Divider({ width = DEFAULT_WIDTH, color = gray.gray3, align }: Props) {
  return <DividerItem style={{ width }} color={color} align={align} />;
}
