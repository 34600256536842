import Icon from '@components/_atoms/Icon';
import { IconName } from '@components/_atoms/Icon/icons';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { primary } from '@styles/Colors';
import Link from 'next/link';
import { UnAuthSelectNavItemType } from '..';

type Props = {
  readonly iconName: IconName;
  readonly title: string;
  readonly content: ContentType[];
  readonly select: boolean;
  readonly onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readonly value: UnAuthSelectNavItemType;
};

type ContentType = {
  link: string;
  content: string;
  steelbosoId: string;
};

const Layout = styled.div`
  padding: 8px;
`;

const TitleContainer = styled.button`
  width: 100%;

  border: none;
  background: none;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const ArrowIcon = styled(Icon)``;

const ContentContainer = styled.div`
  margin-top: 8px;
  padding-left: 36px;
`;

const ContentItem = styled.div`
  padding: 8px;
  cursor: pointer;
`;

export default function AccodianNavItem({ iconName, title, content, select, value, onClick }: Props) {
  return (
    <Layout>
      <TitleContainer onClick={onClick} value={value}>
        <Left style={{ pointerEvents: 'none' }}>
          <Icon name={iconName} stroke={primary.gray} />
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {title}
          </Typography>
        </Left>
        <ArrowIcon name={select ? 'arrow-up' : 'arrow-down'} stroke={primary.gray} style={{ pointerEvents: 'none' }} />
      </TitleContainer>

      {/* only select */}
      {select && (
        <ContentContainer>
          {content?.map((item) => (
            <Link href={item.link} key={item.content}>
              <a data-steelboso-id={item.steelbosoId}>
                <ContentItem>{item.content}</ContentItem>
              </a>
            </Link>
          ))}
        </ContentContainer>
      )}
    </Layout>
  );
}
