import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { black, primary, white } from '@styles/Colors';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  readonly link: string;
  readonly title: string;
  readonly isTransparent: boolean;
  readonly steelbosoId?: string;
};

const Layout = styled.div<{ isCurrent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isCurrent &&
    `
    padding-top:3px;
    border-bottom: 3px solid ${primary.blue};
  `}
`;

export default function NavItem({ link, title, isTransparent, steelbosoId }: Props) {
  const router = useRouter();
  const isCurrent = link === router.pathname;

  const onClick = () => {
    router.push(link);
  };

  return (
    <Layout isCurrent={isCurrent} onClick={onClick} data-steelboso-id={steelbosoId}>
      <Link href={link}>
        <a>
          <Typography style={{ cursor: 'pointer' }} variant={TypoVariant.SH4} color={isTransparent ? white : black}>
            {title}
          </Typography>
        </a>
      </Link>
    </Layout>
  );
}
