import styled from '@emotion/styled';
import Link from 'next/link';

// compomnents
import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';

// constatns
import { TypoVariant } from '@constants/atoms';
import { gray, primary } from '@styles/Colors';

// type
import { GetPushDataResultType } from 'models/SupplementType';
import { Time } from '@utils/time';
import { usePatchPartialPushFetch } from '@hooks/fetch/usePushFetch';
import { useRouter } from 'next/router';

type Props = {
  data: GetPushDataResultType;
};

const Layout = styled.div<{ is_checked?: boolean }>`
  padding: 12px 8px;
  cursor: pointer;

  ${({ is_checked }) =>
    is_checked &&
    `
    opacity: 0.4;
  `}
`;

const CreatedAtWrapper = styled.div`
  text-align: right;
`;

const Head = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 85px;
  column-gap: 8px;
`;

const Content = styled.div`
  margin-top: 8px;
  padding-left: 32px;
`;

export default function PushItem({ data }: Props) {
  const router = useRouter();

  const { id: push_id, title, content, url, is_checked, created_at } = data ?? {};

  const { patchPartialPushMutate } = usePatchPartialPushFetch();

  const handleItemClick = () => {
    function __navigationToPage() {
      router.push(url!);
    }

    if (!is_checked) {
      patchPartialPushMutate(
        { push_id },
        {
          onSuccess: __navigationToPage,
        },
      );
    } else {
      __navigationToPage();
    }
  };

  return (
    <Layout is_checked={is_checked} onClick={handleItemClick}>
      <Head>
        <Icon name="noti-light-on" stroke={primary.blue} />
        <Typography variant={TypoVariant.B4} color={gray.gray6}>
          {title}
        </Typography>
        <CreatedAtWrapper>
          <Typography variant={TypoVariant.B4} color={gray.gray6}>
            {Time(created_at).format('YYYY-MM-DD')}
          </Typography>
        </CreatedAtWrapper>
      </Head>
      <Content>
        <Typography variant={TypoVariant.B3} color={primary.gray}>
          {content}
        </Typography>
      </Content>
    </Layout>
  );
}
