import { LOGIN, SIGNUP } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import NavItem from './NavItem';
import ButtonCode from '@constants/log/buttonCode';

type Props = {
  readonly isTransparent: boolean;
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export default function UnAuthNavList({ isTransparent }: Props) {
  const { t } = useTranslation('common');
  return (
    <Layout>
      <NavItem
        title={t('header.signup')}
        link={SIGNUP}
        isTransparent={isTransparent}
        steelbosoId={ButtonCode.BTN_JOIN}
      />
      <NavItem
        title={t('header.login')}
        link={LOGIN}
        isTransparent={isTransparent}
        steelbosoId={ButtonCode.BTN_LOGIN}
      />
    </Layout>
  );
}
