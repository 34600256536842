import Icon from '@components/_atoms/Icon';
import { IconName } from '@components/_atoms/Icon/icons';
import { IconSize } from '@constants/atoms';
import styled from '@emotion/styled';
import { ForwardedRef, forwardRef } from 'react';

type Props = Styleable & {
  readonly size?: IconSize;
  readonly name: IconName;
  readonly fill?: string;
  readonly stroke?: string;
  readonly onClick?: () => void;
  readonly steelbosoId?: string;
};

const RefIconWrapper = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RefIcon = (
  { size = IconSize.MEDIUM, name, fill = 'transparent', stroke, onClick, steelbosoId }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const width = size;
  const height = size;

  return (
    <RefIconWrapper style={{ width, height }} ref={ref} onClick={onClick} data-steelboso-id={steelbosoId}>
      <Icon name={name} fill={fill} stroke={stroke} style={{ pointerEvents: 'none' }} />
    </RefIconWrapper>
  );
};

export default forwardRef(RefIcon);
