import styled from '@emotion/styled';
import { HOME } from '@constants/routes/routes';
import { useCheckScrollToTop } from '@hooks/interaction/useCheckScrollToTop';
import { gray, white } from '@styles/Colors';
import { useRouter } from 'next/router';
import UnAuthBlock from './UnAuthBlock';
import AuthBlock from './AuthBlock';

import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';
import Banner from '../Banner';

type Props = {
  readonly isTransparent: boolean;
};

const Layout = styled.header<{ isTransparent: boolean }>`
  /* position: fixed; */
  /* top: 0; */
  width: 100%;
  height: 56px;
  padding: 0 24px;
  /* z-index: 999; */
  background: transparent;
  user-select: none;

  transition: all 0.15s ease-in-out;

  /* scroll */
  ${(props) =>
    !props.isTransparent &&
    `
    background: ${white};
    box-shadow: 0px 2px 4px 0px #80808014;
    border-bottom: 1px solid ${gray.gray2};
  `}
`;

export default function Header({ isTransparent }: Props) {
  const router = useRouter();
  const isScrollToTop = useCheckScrollToTop();
  const isTransparentScrollToTop = isTransparent && isScrollToTop;

  const { getUserProfileFetchData: user, getUserProfileIsLoading } = useGetUserProfileFetch();
  const loginStatus = !!user;

  return (
    <>
      <Banner loginStatus={loginStatus} />
      <Layout isTransparent={isTransparentScrollToTop}>
        {loginStatus ? (
          <AuthBlock isTransparent={isTransparentScrollToTop} />
        ) : (
          <UnAuthBlock isTransparent={isTransparentScrollToTop} />
        )}
      </Layout>
    </>
  );
}
