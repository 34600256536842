import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { gray, white } from '@styles/Colors';
import { Time } from '@utils/time';

type Props = {
  readonly userName: string;
  readonly marketApporovalState: boolean;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default function MarketingApprovalMessage({ userName, marketApporovalState }: Props) {
  const userNameMessage = `${userName} 님,`;
  const marketApporovalStateMessage = marketApporovalState ? '수신동의' : '수선거부';
  const message = `광고성 정보가 ${marketApporovalStateMessage} 되었어요`;
  const curDate = Time().format('YYYY.MM.DD');

  return (
    <Layout>
      <Typography variant={TypoVariant.B4} color={white}>
        {userNameMessage}
      </Typography>
      <Typography variant={TypoVariant.B4} color={white}>
        {message}
      </Typography>
      <Typography variant={TypoVariant.B4} color={gray.gray4}>
        {curDate}
      </Typography>
    </Layout>
  );
}
