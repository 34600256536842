import Icon from '@components/_atoms/Icon';
import { IconName } from '@components/_atoms/Icon/icons';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { primary } from '@styles/Colors';
import Link from 'next/link';

type Props = {
  readonly iconName: IconName;
  readonly text: string;
  readonly linkLocation: string;
  readonly steelbosoId?: string;
};

const Layout = styled.div`
  padding: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
`;

const TextContent = styled.div``;

export default function NavItem({ iconName, text, linkLocation, steelbosoId }: Props) {
  return (
    <Layout data-steelboso-id={steelbosoId}>
      <Link href={linkLocation}>
        <a>
          <ContentWrapper>
            <Icon name={iconName} stroke={primary.gray} />
            <TextContent>
              <Typography variant={TypoVariant.SH3}>{text}</Typography>
            </TextContent>
          </ContentWrapper>
        </a>
      </Link>
    </Layout>
  );
}
